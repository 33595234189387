import Vue from 'vue';
import currency from 'currency.js'

const helpers = {
  methods: {
    ticker(value = 0, format = ' ddd') {
      new Odometer({
        el: this.$root,
        value: value,

        // Any option (other than auto and selector) can be passed in here
        format: format,
        duration: 3000,
        theme: 'default',
      });
    },
    NOK(value, opt = {}) {
      let options = {...{symbol: '', decimal: ',', separator: ' ', pattern: `#!`, precision: 0}, ...opt}
      return currency(value, options).format();
    },
    date(value, format = "lll", valueFormat = "YYYY-MM-DD") {
      return this.$moment(value, valueFormat).format(format).toString();
    },
    placeholder(w, h) {
      return "data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 " + w + " " + h + "'%2F%3E";
    },
    srcset(src, output = 'string') {
      let data = src;
      if (!Array.isArray(src)) {
        data = src.split(',').map((item) => {
          let tmp = item.trim().split(' ');
          return {
            url: tmp[0],
            size: parseInt(tmp[1])
          }
        })
      }
      const formated = data.sort((a, b) => a.size - b.size);
      if (output === 'string') {
        return formated.map(item => `${item.url} ${item.size}w`).join(', ');
      }
      return formated;
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...';
    },
    katAssets(id) {
      let bg = "bg-blue",
        text = "text-blue",
        image = "/illustration/annet.svg";

      switch (id) {
        case "annet":
          bg = "bg-blue-900";
          text = "text-blue-900";
          break;
        case "bærekraft":
        case "miljø-og-bærekraft":
          bg = "bg-green-700";
          text = "text-green-700";
          image = "/illustration/baerekraft.svg";
          break;
        case "idrett-og-friluftsliv":
        case "idrett":
          bg = "bg-orange-500";
          text = "text-orange-500";
          image = "/illustration/" + id + ".svg";
          break;
        case "kunst-og-kultur":
        case "kultur":
          bg = "bg-red-500";
          text = "text-red-500";
          image = "/illustration/kunst-og-kultur.svg";
          break;
        case "fellesskap":
        case "humanitært":
          bg = "bg-red-700";
          text = "text-red-700";
          image = "/illustration/fellesskap.svg";
          break;
        case "innovasjon-og-verdiskaping":
        case "næringsutvikling":
          bg = "bg-blue";
          text = "text-blue";
          image = "/illustration/innovasjon.svg";
          break;
        case "byrom":
          bg = "bg-red-600";
          text = "text-red-600";
          image = "/illustration/byrom.svg";
          break;
        case "friluftsliv":
          bg = "bg-green-500";
          text = "text-green-500";
          image = "/illustration/friluftsliv.svg";
          break;
      }

      return {
        bg: bg,
        text: text,
        image: image,
      }
    }
  }
}

Vue.mixin(helpers);
